import fetch from 'isomorphic-unfetch'

export type Request = {
  token?: string
  url: URL
  method: string
  body?: object | string
  headers?: HeadersInit
  withoutJson?: boolean
}

export type Response = {
  status: string
  message?: string
}

export type ResponseWithSuccess = Response

export type ResponseWithError = {
  errors: string[]
} & Response

export type ResponseWithId = {
  id: string
}

export type ResponseWithBool = {
  state: boolean
}

export type ResponseWithJWT = {
  token: string
}

export type PaginatedApiResponse<T> = {
  data: T[]
  page: number
  perPage: number
  totalRecords: number
  searchValue?: string
}

export type DateMeta = {
  createdAt: Date | string
  updatedAt?: Date | string
}

export function convertDateAttributes(data: DateMeta) {
  if (data.createdAt) {
    data.createdAt = new Date(data.createdAt.toString().replace(/\s+/g, 'T'))
  }
  if (data.updatedAt) {
    data.updatedAt = new Date(data.updatedAt.toString().replace(/\s+/g, 'T'))
  }
  return data
}

export function fixPagination(response: PaginatedApiResponse<any>) {
  response.page -= 1
  return response
}

export function convertDateAttributesInArray(data: DateMeta[]) {
  return data.map((item: DateMeta) => {
    return convertDateAttributes(item)
  })
}

export function createUrl(relativUrl: string) {
  if (process.browser && process.env.NODE_ENV !== 'development') {
    return new URL(relativUrl, `https://api.${process.env.DOMAIN}`)
  }
  if (process.browser && process.env.NODE_ENV === 'development') {
    return new URL(relativUrl, `http://api.${process.env.DOMAIN}`)
  }

  return new URL(relativUrl, `http://${process.env.STACK_NAME ? `${process.env.STACK_NAME}_` : ''}api`)
}

export async function useFetch(request: Request) {

  if (!request.headers) {
    request.headers = {}
  }

  if (!request.withoutJson) {
    request.headers['Accept'] = 'application/json'
    request.headers['Content-Type'] = 'application/json'
    request.body = JSON.stringify(request.body)
  }

  if (request.token) {
    request.headers['Authorization'] = `Bearer ${request.token}`
  }

  try {
    const response = await fetch(request.url.toJSON(), {
      method: request.method,
      headers: request.headers,
      body: request.body as BodyInit,
    })

    if (response.ok) {
      return response.json()
    } else {
      return Promise.reject(await response.json())
    }
  } catch (error) {
    // Implementation or network error
    console.error('You have an error in your code or there are network issues.', error)
    return Promise.reject(error)
  }
}

export async function download(request: Omit<Request, 'method'>) {
  if (!request.headers) {
    request.headers = {}
  }

  if (request.token) {
    request.headers['Authorization'] = `Bearer ${request.token}`
  }

  try {
    const response = await fetch(request.url.toJSON(), {
      method: 'GET',
      headers: request.headers,
    })

    if (response.ok) {
      const filename = response!
        .headers!.get('Content-Disposition')!
        .split('filename=')[1]
        .split(';')[0]
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      })
    }
  } catch (error) {
    // Implementation or Network error
    console.error('You have an error in your code or there are Network issues.', error)
    return Promise.reject(error)
  }
}
