import React from 'react'
const IconCheckMark = props => (
  <svg {...props} width="15px" height="13px" viewBox="0 0 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Elements/Icon/Actions/Verlinkung"
        transform="translate(-6.000000, -6.000000)"
        stroke="#42D8B0"
        strokeWidth="1.5"
      >
        <path
          d="M16.9550328,6.98125103 L11.1914339,12.7433001 L9.06403408,10.6159816 L6.83090506,12.8491106 L8.81028634,14.831708 L8.78142355,14.8013545 L11.1911824,17.2111134 L19.1880388,9.214257 L16.9550328,6.98125103 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
)

export default IconCheckMark
