import React from 'react'
const IconMoveDown = props => (
  <svg {...props} width="13px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Elements/Icon/Actions/Move-Down" transform="translate(-6.000000, -7.000000)" fill="currentColor">
            <path d="M11.6112155,7.35891249 L6.11121548,16.3589125 C5.80580361,16.8586774 6.16547954,17.5 6.75117686,17.5 L17.7511769,17.5 C18.3368742,17.5 18.6965501,16.8586774 18.3911382,16.3589125 L12.8911382,7.35891249 C12.598691,6.8803625 11.9036627,6.8803625 11.6112155,7.35891249 Z M12.2511769,9.187 L16.4141769,16 L8.08717686,16 L12.2511769,9.187 Z" id="Triangle" transform="translate(12.251177, 12.250000) scale(-1, 1) rotate(180.000000) translate(-12.251177, -12.250000) "></path>
        </g>
    </g>
  </svg>
)

export default IconMoveDown
