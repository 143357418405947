import React from 'react'
const IconMoveUp = props => (
  <svg {...props} width="13px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Elements/Icon/Actions/Move-Up" transform="translate(-6.000000, -6.000000)" fill="currentColor">
            <path d="M11.8600386,6.60891249 L6.36003862,15.6089125 C6.05462676,16.1086774 6.41430269,16.75 7,16.75 L18,16.75 C18.5856973,16.75 18.9453732,16.1086774 18.6399614,15.6089125 L13.1399614,6.60891249 C12.8475142,6.1303625 12.1524858,6.1303625 11.8600386,6.60891249 Z M12.5,8.437 L16.663,15.25 L8.336,15.25 L12.5,8.437 Z" id="Triangle"></path>
        </g>
    </g>
  </svg>
)

export default IconMoveUp
